<template>
    <div>

        <PageHeader title="Carriers">
            <div class="actionBtns">
                <!-- <button class="default medium round _icon" @click="inviteCarriersAndDriver">Bulk invite<i class="fas fa-plus"></i></button> -->
                <button class="default medium round _icon" v-if="isUserPermitted('carriers', 'add')" @click="addItem = true">Add carrier<i class="fas fa-plus"></i></button>
            </div>
        </PageHeader>

        <Modal v-model="addItem" title="Add carrier">
            <Add @add="carrierAdded"/>
        </Modal>

        <Modal v-model="editItem" @close="deselect" :hideTitle="true">
            <Edit v-if="selectedItem" @updated="carrierAdded" @remove="removeItem" :carrier="selectedItem" @invite="inviteCarrier" @copyInvite="copyInvitationLink" @deleteAccount="deleteAccount"/>
        </Modal>
        


        <div class="wrapper">
            <Table class="itemTable" :cols="['Name', 'MC #', 'Email', 'Phone', 'Invite link', 'Action']">
                <div class="row" :key="key" v-for="(item, key) of items" @dblclick="editItemDetails(item)">
                    <div>{{ item.name }}</div>
                    <div>{{ item.mcNumber }}</div>
                    
                    <div v-if="item.credentials">{{ item.email }}</div>
                    <div v-else><a href="" @click.prevent="inviteCarrier(item._id, 'email')" v-tooltip="`Invite via email`" v-if="item.email">{{ item.email }}</a></div>

                    <div v-if="item.credentials">{{ item.phone }}</div>
                    <div v-else><a href="" @click.prevent="inviteCarrier(item._id, 'sms')" v-tooltip="`Invite via SMS`" v-if="item.phone">{{ item.phone }}</a></div>
                    
                    <div><button v-if="!item.credentials" class="default small round reverse" v-tooltip="`Copy invitation link`" @click="copyInvitationLink(item._id)"><i class="fa-solid fa-copy"></i></button></div>
                    <div><button class="default small round reverse" @click="editItemDetails(item)">View</button></div>
                </div>
            </Table>
        </div>


    </div>
</template>

<script>
import Add from '../../components/carriers/Add.vue'
import Edit from '../../components/carriers/Edit.vue'
import Invite from '../../components/carriers/Invite.vue'
    export default {
        components: {
            Add,
            Edit
        },
        data() {
            return {
                addItem: false,
                editItem: false,
                selectedItem: null,
                items: []
            }
        },
        methods: {
            deleteAccount(id) {
                let confirmDelete = confirm("Do you really wish to delete carrier's account? Carrier won't be able to access it.");
                if(!confirmDelete) return;

                this.editItem = false;

                this.ajax('inviteCarrier', {
                    url: `/carrier/deleteAccount/${id}`,
                    method: 'GET',
                }, (err, body) => {
                    
                    this.$notify({
                        type: 'info',
                        text: `Carrier's account was deleted`
                    });

                    this.getItems();

                });

            },
            inviteCarrier(id, type) {

                this.ajax('inviteCarrier', {
                    url: '/carriers/invite',
                    method: 'POST',
                    data: {
                        carriers: [id],
                        type: type
                    }
                }, (err, body) => {

                    this.$notify({
                        type: 'info',
                        text: `Message will be sent via selected method`
                    });

                });
            },
            copyInvitationLink(id) {
                let link = (process.env.VUE_APP_CARRIER_INVITATION_LINK || 'https://example.com') + "/invitation/" + id;
                this.copyTextToClipboard(link);
                this.$notify({
                    type: 'info',
                    text: 'Invitation link was copied'
                });
            },
            inviteCarriersAndDriver() {
                this.$showModalSimple(Invite, 'Invite', {});
            },
            carrierAdded() {
                this.getItems();
                this.addItem = false;
                // this.editItem = false;
            },
            removeItem(id) {

                this.editItem = false;

                this.$axios.delete(`/carriers/${id}`)
                .then(data => {
                    this.$notify({
                        type: 'info',
                        text: 'Carrier has been removed successfully'
                    });
                    this.getItems();
                })
                .catch(err => {
                    let response = err.response;

                    if(response && response.data) {
                        let body = response.data;
                        if(body.errors) this.errors = body.errors;
                        else if(body.message) alert(body.message);
                        else alert("Something went wrong. Please, try again!");
                    } else {
                        alert("Something went wrong. Please, try again!");
                    }

                });
            }, 
            getItems() {
                this.$axios.get('/carriers')
                .then(data => {
                    this.items = data.data;
                });
            },
            editItemDetails(item) {
                this.selectedItem = item;
                this.editItem = true;
            },
            pushItem(item) {
                this.items.push(item);
            },
            deselect() {
                this.selectedItem = null;
            }
        },
        mounted() {
            this.getItems();
        }
    }
</script>


<style lang="scss">
.itemTable {
    .row {
        grid-template-columns: repeat(4, minmax(0, 1fr)) 100px 150px !important;
    }
}
</style>

<style lang="scss" scoped>

.itemTable {
    .row {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr)) 100px 150px !important;
        height: 60px;
        align-items: center;
        padding: 10px 20px;
        column-gap: 20px;

        &:nth-child(2n) {
            background: $bg;
        }
        &:last-child {
            border-radius: 0 0 $borderRadius $borderRadius;
        }
    }
}

.editBtn {
    border: 0;
    background: transparent;
    width: 34px;
    height: 34px;
    padding: 0;
}

.actionBtns {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 10px;
}
</style>