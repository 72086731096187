<template>
    <div class="addCarrier">
        <div class="sections">
            <div class="categories">
                <button class="default small round" 
                :key="key" v-for="(item, key) of sections" 
                :class="{ 'active' : key == selectedSection }"
                @click="selectedSection = key">{{ item.name }}</button>
            </div>
            <div class="componentContent">
                <component v-bind:is="sections[selectedSection].component" :errors="errors" :isEdit="true" :carrier="carrier"></component>
            </div>
        </div>

        <button class="default" @click="addCarrier">Add carrier</button>

    </div>
</template>

<script>

    import CarrierDetails from './CarrierDetails.vue';
    import CarrierDocuments from './CarrierDocuments.vue';

    export default {
        components: {
            CarrierDetails,
            CarrierDocuments
        },
        data() {
            return {
                sections: [
                    { name: 'Details', component: 'CarrierDetails' },
                    { name: 'Documents', component: 'CarrierDocuments' },
                ],
                selectedSection: 0,
                carrier: {},
                errors: {}
            }
        },
        methods: {
            addCarrier() {
                this.errors = {};

                let body = JSON.parse(JSON.stringify(this.carrier));
                
                this.$axios.post('/carriers', body)
                .then(data => {
                    let body = data.data;

                    this.$notify({
                        type: 'info',
                        text: 'Carrier has been added successfully'
                    });

                    this.carrier = {};
                    this.$emit('add');
                })
                .catch(err => {
                    let response = err.response;

                    if(response && response.data) {
                        let body = response.data;
                        if(body.errors) this.errors = body.errors;
                        else if(body.message) alert(body.message);
                        else alert("Something went wrong. Please, try again!");
                    } else {
                        alert("Something went wrong. Please, try again!");
                    }

                });
            }
        },
    }
</script>

<style lang="scss" scoped>

.sections {
    display: grid;
    row-gap: 20px;
    .categories {
        display: flex;
        column-gap: 10px;
        button {
            border: 1px solid $buttonColor;
            background: $bg;
            color: $buttonColor;
            &.active {
                background: $buttonColor;
                color: $buttonText;
            }
        }
    }
}

.componentContent {
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    background: $bgSecondary;
}

.addCarrier {
    display: grid;
    padding: 0;
    row-gap: 50px;
}
</style>