<template>
    <div class="invitationGrid">

        <!-- <div class="formHolder">

            <div class="input">
                <div class="_header">
                    <label>Carrier</label>
                </div>
                <div class="inputHolder">
                    <select v-model="selection.carrier">
                        <option :value="undefined">-- Select carrier --</option>
                        <option :key="carrier._id" :value="carrier._id" v-for="carrier of carriers">{{ carrier.name }}</option>
                    </select>
                </div>
            </div>

            <div class="input" v-if="selection.carrier">
                <div class="_header">
                    <label>Driver</label>
                </div>
                <div class="inputHolder">
                    <select v-model="selection.driver">
                        <option :value="undefined">-- Select driver --</option>
                        <option :key="driver._id" :value="driver._id" v-for="driver of drivers">{{ driver.name }} {{ driver.lastname }}</option>
                    </select>
                </div>
            </div>

            <button v-if="selection.carrier" class="default medium" @click="invite">Invite</button>
            

        </div>

        <hr> -->
        <div class="actionBtns">
            <button class="default medium" @click="inviteAll('all')">Invite all</button>
            <button class="default medium" @click="inviteAll('carriers')">Only carriers</button>
            <button class="default medium" @click="inviteAll('drivers')">Only drivers</button>
        </div>
        
    </div>
</template>

<script>
    export default {
        data() {
            return {
                selection: {},
                carriers: [],
                drivers: [],
            }
        },
        watch: {
            "selection.carrier"() {
                if(this.selection.carrier) {
                    this.getDrivers();
                }
            }
        },
        methods: {
            invite() {
                 this.ajax('bulkInvite', {
                    url: '/carriers/invite',
                    method: 'POST',
                    data: {
                        carriers: this.selection.carrier ? [this.selection.carrier] : [],
                        drivers: this.selection.driver ? [this.selection.driver] : [],
                    }
                }, (err, body) => {
                    
                });
            },
            inviteAll(type) {
                 this.ajax('bulkInvite', {
                    url: '/carriers/invite',
                    method: 'POST',
                    data: {
                        invite: type
                    }
                }, (err, body) => {
                    
                });
            },
            getCarriers() {
                this.ajax('getListOfCarriers', {
                    url: '/carriers',
                    method: 'GET'
                }, (err, body) => {
                    if(!err) this.carriers = body;
                });
            },
            getDrivers() {
                this.drivers = [];
                this.ajax('getListOfCarriers', {
                    url: `/carrier/drivers/${this.selection.carrier}`,
                    method: 'GET'
                }, (err, body) => {
                    if(!err) this.drivers = body;
                });
            }
        },
        mounted() {
            this.getCarriers();
        }
    }
</script>

<style lang="scss" scoped>
hr {
    border: 0;
    border-top: 1px solid $borderColor;
}

.invitationGrid {
    display: grid;
    row-gap: 20px;
}

.actionBtns {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 10px;
}

.formHolder {
    display: grid;
    row-gap: 10px;
}
</style>